<template>
    <v-row>
        <v-col cols="12" class="mt-2 mb-5">

            <v-btn v-if="login_yn == false"
                   color="#5EB4F9"
                   depressed
                   outlined
                   block
                   style="color:#5EB4F9"
                   @click="login"
            >
                로그인
            </v-btn>

            <v-btn v-else
                   color="#5EB4F9"
                   depressed
                   block
                   dark
                   @click="logout"
            >
                로그아웃
            </v-btn>

            <v-btn v-if="login_yn == true"
                   color="#5EB4F9"
                   depressed
                   block
                   dark
                   @click="myauth"
            >
                본인인증
            </v-btn>

        </v-col>

        <v-divider class="ml-3 mr-3"  v-if="login_yn == true"></v-divider>

        <v-col cols="12" class="mt-2 mb-5" v-if="login_yn == true">
            <v-subheader>기본 정보</v-subheader>
            <v-simple-table>
                <tbody>
                <tr v-for="(item, i) in info_datas"
                    :key="i"
                    @click="toDetail(item)"
                >
                    <td class="d-flex align-center">
                        <v-icon class="myinfo_icon_color mr-2">
                            {{ item.icon }}
                        </v-icon>
                        {{ item.menu }}
                    </td>
                    <td class="text-right">
                        <v-icon>
                            chevron_right
                        </v-icon>
                    </td>
                </tr>
                </tbody>
            </v-simple-table>
        </v-col>

        <v-divider class="ml-3 mr-3"></v-divider>

        <v-col cols="12" class="mt-2 mb-5">
            <v-subheader>회사 정보</v-subheader>
            <v-simple-table>
                <tbody>
                <tr v-for="(item, i) in notice_datas"
                    :key="i"
                    @click="toDetail(item)"
                >
                    <td class="d-flex align-center">
                        <img :src="item.icon"
                             class="mr-2"
                        />
                        {{ item.menu }}
                    </td>
                    <td class="text-right">
                        <v-icon>
                            chevron_right
                        </v-icon>
                    </td>
                </tr>
                </tbody>
            </v-simple-table>
        </v-col>

    </v-row>
</template>

<script>
    import axios from '../../../service/axios'
    
    export default {
        name: 'MyInfo',
        components: {

        },
        data(){
            return {
                login_yn: true,
                info_datas: [
                    {
                        menu: '회원정보',
                        icon: 'account_circle',
                        link:'/detail/userinfo',
                    },
                    {
                        menu: '판매/구매 내역',
                        icon: 'local_mall',
                        link:'/detail/history',
                    },
                    {
                        menu: '삭제내역',
                        icon: 'backspace',
                        link:'/detail/delhistory',
                    },
                ],
                notice_datas: [
                    {
                        menu: '공지사항',
                        icon: 'svg/notice.svg',
                        link:'/detail/notice',
                    },
                    {
                        menu: '고객센터',
                        icon: 'svg/service_center.svg',
                        link:'/detail/servicecenter',
                    },
                    {
                        menu: '약관 및 정책',
                        icon: 'svg/term_service.svg',
                        link:'/detail/termservice',
                    },

                ],
            }
        },
        created () {
            var orderId = this.$route.query.orderId;
            var paymentKey = this.$route.query.paymentKey;
            var amount = this.$route.query.amount;

            console.log(orderId, paymentKey, amount);

            var skey = 'test_sk_MGjLJoQ1aVZNKLLvBQQVw6KYe2RN';
            var bkey = btoa(skey + ':');
            console.log(bkey)

            const headers = {
                "Authorization": `Basic ${bkey}`,
                "Content-Type": "application/json"
            };

            const config = {
                headers: headers
            };

            const params = { 
                orderId: orderId, 
                amount: amount,
            };

            const url = 'https://api.tosspayments.com/v1/payments/'+paymentKey;
            axios.post(url, params, config).then(res =>{
                console.log(res)
                var data = res.data;
                console.log(data)

                var status = data.status;
                var res_orderId = data.orderId;
                if(status == 'DONE'){
                    console.log(`결제 성공. 주문번호 ${res_orderId}`)
                    this.payment_complete(res_orderId, '충전완료', amount);
                }else{
                    console.log(`결제 실패. 주문번호 ${res_orderId}`)
                    this.payment_complete(res_orderId, '충전실패', amount);
                }
                
            }).catch(error=>{
                console.log('결제승인 오류', error);
                console.log(`결제 실패. 주문번호 ${orderId}`);
                this.payment_complete(orderId, '충전에러', amount);
            });
        },
        methods: {
            payment_complete(order_id, type, amount){
                const params = {
                     order_id: order_id, 
                     type: type,
                     accessKey: this.$cookies.get('token'),
                };

                axios.post('/api/payment/complete', params).then(res =>{
                    console.log('결제완료 처리 성공.',res);
                    if(type == '충전완료'){
                        this.fillMoney(amount);
                    }
                }).catch(error=>{
                    console.log('결제완료 처리 오류.', error)
                });
            },
            fillMoney(amount){
                const payment_info = {
                    deposit_ammount: parseInt(amount),
                    deposit_type: '입금'
                };
                const params = {
                    accessKey: this.$cookies.get('token'),
                    options: payment_info
                }
                axios.post('/api/payment/createDepositCharge', params).then(res =>{
                    console.log(res);
                    // this.$router.go(-2);
                    this.$router.push('/');
                }).catch(error=>{
                    console.log('페스트 오류', error)
                });
            },
        }
    }

</script>

<style lang="scss">
    .preview_img{
        .v-image__image--cover {
            background-size: contain;
        }
    }
    .preview{
        .v-btn--icon.v-size--small{
            width: 10px;
            height: 10px;
        }
    }
    .subheader{
        font-size: 0.85rem;
        align-self: center;
    }
    .service_center{
        font-size: 12px;
        letter-spacing: -0.001em;
    }
    .myinfo_icon_color{
        color: #a3a3a3 !important;
    }
</style>